// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//const BASE_API = 'http://83.166.154.165';
const BASE_API = 'https://services.pdg-dev.com';
//const BASE_API = 'https://services.pdg-preprod.com';
export const environment = {
  allowAll:false,
  production: false,
  apiAuthUrl: BASE_API + '/auth_services/api',
  apiUrl: BASE_API + '',
  apiAdminUrl: BASE_API + '/admin_services/api',
  apiReseauUrl: BASE_API + '/reseau_services/api',
  apiTransfertUrl: BASE_API + '/transfert_services/api',
  apiWalletUrl: BASE_API + '/wallet_services/api',
  apiLogsUrl: BASE_API + '/logs_services/api',
  apiAmlUrl: BASE_API + '/aml_services/api',
  apiMassPaymentUrl: BASE_API ,
  apiComplianceUrl: 'https://fraude-services.bgdigital.tech',
  apiSimBotUrl: 'https://services.pdg-dev.com/simbot_services_v1/api',
  apiParterUrl: 'https://services.pdg-dev.com/partners_services/api',
  urlFront:'https://reseau.nt-albey.ne/#/sessions/signin',
  urlFrontforotNT:'https://reseau.nt-albey.ne/#/sessions/forgot',
  urlFrontfirstNT:'https://reseau.nt-albey.ne/#/sessions/updatePwd',
  urlFrontstandard:'https://reseaux.pdg-dev.com/#/sessions/signin',
  urlFrontforot:'https://reseaux.pdg-dev.com/#/sessions/forgot',
  urlFrontfirst:'https://reseaux.pdg-dev.com/#/sessions/updatePwd',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
