
// Français
export const locale = {
  lang: 'fr',
  data: {
    TRANSLATOR: {
      SELECT: 'Choisissez votre langue',
    },

    GENERAL:{
      HOME:'Accueil',
      LISTEVIDE:"Aucun élément trouvé",
      OBLIGATOIRE:"Champs obligatoire.",
      VPatientez:"Veuillez patientez....",
      TOUS:'Voir Tous',
      FOUND:'retrouvé(s)',
      LOGOUT:'Déconnexion',
      PROFILE:'Mon Profil',
      MONEY_TRANSFER:'Transfert D\'argent',
      OFFICE:'Guichet unique',
      MASS_PAYMENT:'Paiement de masse',
      MASS_PAYMENT_S:'Paiement de salaire, d\'aides, de bourses ...',
      WALLET:'Wallet',
      OPERATION_WALLET:'Cashin, Cashout, Airtime ....',
      BILL_PAYMENT:'Paiement de facture',
      BILL_PAYMENT_DESC:'Paiement de facture ,eau, électricite',
      TRANSACTIONS:'Transactions',
      SENDING:'Envois',
      WITHDRAWALS:'Retraits',
      AMOUNT:'Montant',
      EXPENSE:'Frais',
      COMMISSIONS:'Commissions',
      DEBIT_AMOUNT:'Montant débit',
      CREDITS_AMOUNT:'Montant crédit',
      DAY:'Jour',
      NUMBER:'Nombre',
      TOP_UP_BALANCE:'Approvisionner mon solde',
      BALANCE:'Solde',
      CASH_BALANCE:'Solde Cash',
      AGENCY:'Agence',
      BALANCE_COM_ENT:'Solde Commission Entité',
      BALANCE_MERCHANT:'Solde marchand',
      COOPERATIVE:'Coopérative',
      CREATED_BY:'Créé par',
      VALIDATE_BY:'Validè par',
      STATE:'Etat',
      SHARES:'Actions',
      CHOOSE:'--Choisir--',
      ENABLLED:'Activé',
      DISABLED:'Dèsactivé',
      WAITING:'En attente',
      FILTERS:'Filtres',
      DATE:'Date',
      CONFIRMATION:'CONFIRMATION',
      YES:'OUI',
      NO:'NO',
      VALIDATE:'Valider',
      REJECT:'Rejeter',
      CRATE:'Caisse',
      REGIONS:'Régions',
      DEPARTMENT:'Départements',
      MUNICIPALITIES:'Communes',



    },

    AUTH: {
      LOGIN: {
        BUTTON: 'Se Connecter',
        CONNECT : 'CONNEXION',
        USERNAME : 'Nom d\'utilisateur',
        PASSWORD: 'Mot de passe',
        LOAD_CONNECT: 'Connexion en cours ...',
        ERROR_EN: 'Votre compte n\'est pas lié à une entité. Veuillez contacter le service assitance.',
        EN_NOTFOUND: 'Entité introuvable',
      },
      NOUVEAUPASSWORD: {
        TITLE: 'Configurer un nouveau mot de passe ?',
        SOUSTITLE: 'Vous avez déjà réinitialisé votre mot de passe ?',
      },
      FORGOT: {
        TITLE: 'Mot de passe oublié?',
        DESC: 'Entrez votre e-mail pour réinitialiser votre mot de passe.',
        SUCCESS: 'Your account has been successfully reset.',
        DEMANDE: 'Votre demande a été envoyer avec succés.Vous allez recevoir un mail pour changer votre mot de passe.',
        ENREGISTER : 'Envoyer',
        ANNULE : 'Annuler',
        ECHEC_TOKEN: 'Echec',
        ECHEC_TOKEN_MGS: 'Votre session a expiré Veuillez tenter de regénérer a nouveau votre mode passe',
        LENGHTPWD: ' Le mot de passe doit contenir minimun 8 caractère.',
        CONFIRM_FIELD_PWD:'Les deux mot de passe doivent être identique.',
        SET_CONNECT: 'Nouveau mot de passe',
      },
    },
    BUTTONS:{
      ADD_BUTTON: 'Ajouter',
      CANCEL_BUTTON : 'Annuler',
      UPDATE_BUTTON : 'Modifier',
      DEACTIVATE_BUTTON : 'Désactiver',
      ACTIVATE_BUTTON : 'Activer',
      SUBMIT_BUTTON: 'Enregistrer',
      VALIDATE_BUTTON: 'Valider',
      CONFIRM_BUTTON: 'Confirmer',
      FILTER: 'Filtrer',
      SEARCH: 'Rechercher',
      EXPORT: 'Exporter',
      EXPORT_CSV: 'CSV',
      EXPORT_PDF: 'PDF',
      NEXT: 'Suivant',
      PREVIOUS: 'Precedant',
      BACK: 'Retour',
      CONTINUE: 'Continuer',
      GALLERY: 'Galerie',
      DETAIL: 'Détail',
      FINISH: 'Terminer',
    },
    SIDEBAR:{
      DASHBOARD:'Tableau de bord',
      STAFF:{
        STAFF:'Personnel',
        USERS:'Utilisateurs',
        ROLES:'Rôles',
        PERMISSIONS:'Permissions',
        POINT_LOCATION:'Localisation Des Points',
      },
      AGENCIES:{
        AGENCIES_S:'Points de ventes',
        AGENCIES:'Agences',
        SUPPLIES:'Approvisionnements',
      },
      CRATES:{
        CRATES:'Caisses',
        LIST:'Liste',
        SUPPLIES_CRATES:'Approvisionnements Caisses',
        MERCHANT_POINTS:'Points Marchand'
      },
      REPORTING:{
        REPORTING: 'Reporting',
        ALL:'Tous',
        RECEIPTS:'Encaissements',
        DISBURSEMENT:'Décaissements',
        ACCOUNT_MONITORING:'Suivi comptes',
        PHONE_ACCOUNT_BALANCE:'Solde Compte Téléphone',
        CASH_STATUS:'Situation Caisse',
        PHONE_SMS:'Message téléphone',
      },
      DISTRIBUTORS:{
        DISTRIBUTORS:'Distributeurs'
      },
      COOPERATIVES:{
        COOPERATIVES:'Coopératives',
        LIST_COOPERATIVES:'Liste Coopératives',
        SUPPLIES:'Approvisionnements',
      },
      MERCHANTS:{
        MERCHANTS:'Marchands',
        FILE_LOADING:'Chargement fichier',
        LIST_MERCHANTS:'Liste marchands',
        LIST_CODE_MERCHANTS:'Liste Codes Marchands',
        LIST_APPEAL:'Liste appels de fonds',
        LIST_TRANSACTIONS:'Liste transactions',
      },
      CUSTOMERS:{
        CUSTOMERS:'Clients',
        NEW_CUSTOMERS:'Nouveau Client',
        LIST_WAIT_CUSTOMERS:'Liste En Attente',
        UNCAPPING:'Déplafonnements'
      },
      PRODUCERS:{
        PRODUCERS:'Producteurs'
      },
      LOGS:{
        LOGS:'Logs'
      },
      MESSAGES:{
        MESSAGES:'Messages'
      },
      PAYMENTS:{
        PAYMENTS:'Versements',
        NEW_PAYMENTS_B:'Nouveau  versement banque',
        NEW_APPEAL:'Nouveau  appel de  fond',
        NEW_PAYMENTS_A:'Nouveau  versement Agence',
        LIST_PAYMENTS:'Liste des  Versements',
      },
      TRANSFERS:{
        TRANSFERS:'Transferts',
        CANCELATION:'Annulation',
        REFUND:'Remboursement',
        TRANSFERS_FUND:'Transfert de fonds',
      },
      TRANSACTIONS:{
        TRANSACTIONS:'Transactions',
        LISTE:'Listes',
        TRS_WALLET:'Transactions client wallet',
        TRS_WAIT:'Transactions en attente',
        TRSF_UV:'Transfert UV',
        SUBSTANTIVE_APPEAL:'Appel de fond',
        SUBSTANTIVE_APPEAL_A:'Appel de fond Agence',
        TRS_SIM:'Transactions Simbot',
        TRS_PARTENERS:'Transactions Partenaire',
        TRS_LOTO:'Transactions Gain Loto',
        TRS_INT:'Transactions International en attente',
        TRS_CASH:'Transactions Cash',
        TRS_OFFERS:'Transactions Telecoms',
      },
      SUBSTANTIVE_APPEAL:{
        SUBSTANTIVE_APPEAL:'Appel de fond',
        SUBSTANTIVE_CRATES:'Appel de fond caisse',
        SUBSTANTIVE_AGENCIES:'Appel de fond Agence',

      },
      CARDS:{
        CARDS:'CARTES',
        DISTRIBUTIONS_A:'Distributions agences',
        DISTRIBUTIONS_C:'Distributions caisse',
        RECEPTIONS:'Réceptions',
        PRICE_CARDS:'Prix Cartes',
        LISR_CARDS:'Liste des cartes',
        BLOCKED_CARDS:'Cartes Bloquèes',
        BACK_CARDS_C:'Retour Cartes-Caisse',
        BACK_CARDS_A:'Retour Cartes-Agence',
        RECEPTIONS_CARDS_A:'Réception Cartes-Agence',
        SUPPLIES:'Approvisionnements',
        RESEARCH_CARDS:'Recherche Carte',
        REPORTING_CARDS:'Reporting',
      },
      CAMPAIGNS:{
        CAMPAIGNS:'Campagnes',
        LIST_CAMPAIGNS:'Liste campagne',
      },
      RESTRICTIONS:{
        RESTRICTIONS:'Limitations',
        ALL:'General',
        CARDS:'Carte',
        TPE:'TPE',
        DAB:'DAB',
        WALLET:'Wallet',
      },
      RULES:{
        RULES:'Régles',
        LIST:'Liste',
        EVENTS:'Evenements'
      },
      ENROLLMENT:{
        ENROLLMENT:'Enrôlement',
        NEW:'Nouveau',
        LIST:'Liste',
        NEW_PIECES_C:'Nouvelle piéce conformité',
        LIST_PIECES_C:'Liste piéce conformité',
      },
      TRACKERS:{
        TRACKERS:'Pisteurs',
        LIST_TRACKERS:'Liste des Pisteur',
        SUPPLIES:'Approvissionnement',
        SUBSTANTIVE_APPEAL:'Appel de fond',
      },
      CASH_SUPPLY:{
        CASH_SUPPLY:'Appro Cash',
        CASH_SUPPLY_A:'Agence approvisionnement cash',
        CASH_SUPPLY_C:'Caisse approvisionnement cash'
      },
      ITEMS:{
        ITEMS:'Articles',
        lIST_DISTRIBUTION:'Liste distribution',
        lIST_BOOKLETS:'Liste des livrets',
        NEW_DISTRIBUTIONS_A:'Nouvelle distribution agence',
        NEW_DISTRIBUTIONS_C:'Nouvelle distribution caisse',
        INVENTOR_DISTRIBUTION:'Répartion stock',
      }

    },
    DASHBOARD:{
      ACTIVITIES:'Activités',
      DASHBOARD:'Tableau de bord',
      TODAY:'Aujourd\'hui',
      START:'Début:',
      END:'Fin:',
      AGENCIES:'Agences',
      COOPERATIVES:'Coopératives',
      SERVICES:'Services',
      NUMBER_TRS:'Nombre de transactions',
      AMOUNT_TRS:'Montant des transactions',
      NUMBER_SUPPLIES:'Nombre Appro',
      AMOUNT_SUPPLIES:'Montant des appro',
      DEBITS:'Débits',
      AMOUNT_DEBITS:'Montant Débits',
      CREDITS:'Crèdits',
      AMOUNT_CREDITS:'Montant crèdits',
      AMOUNT_SERVICES:'Montant commissions',
      NUMBER_COOPERATIVES:'Nombre de coopérative',
      NUMBER_AGENCIES:'Nombre d\'agence',
      AMOUNT_COOPERATIVES:'Montant alloué aux coopératives',
      AMOUNT_AGENCIES:'Montant alloué aux agences',
      AMOUNT_OM:'Solde orange money',
      BALANCE:'Solde global',
      BALANCE_AGENCIES:'Solde global de mon agence',
      NUMBER_C:'Nombre de caisses',
      AMOUNT_C:'Montant alloué aux caisses',
      MY_SUBSCRIPTION:'Mes abonnements',
      BEST_AGENCIES:'Meilleures agences',
      BEST_COOPERATIVES:'Meilleures coopératives',
      BEST_SERVICES:'Meilleures services',
      RANKING_SERVICES:'Classement des meilleurs services',
      RANKING_BEST:'Classement des meilleurs',
      BY_TRS_AMOUNT:'par montant de transactions',
      COMMISSIONS_MONTH:'Commissions du mois',
      TRANSACTION_MONTH:'Transaction du mois',
      BEST_CRATES:'Meilleures caisses',
      BEST_POINT:'Meilleures Point Marchand',
      POINT_MERCHANT:'Point Marchand',
      CRATES:'Caisses',
      COMMISSION_CRATES:'Commission caisse',
      TOTAL_COMMISSION_CRATES:'Total Commissions',
      DISTRIBUTORS:'Distributeurs',
      BEST_DISTRIBUTORS:'Meilleures Distributeurs',
      COMMISSIONS_DISTRIBUTORS:'Commissions Distributeur',
    },
    STAFF:{
      USERS:{
        USERS:'Utilisateurs',
        FULLNAME:'Prénom & Nom',
        LASTNAME:'Nom',
        FIRSTNAME:'Prénom',
        PHONE:'Téléphone',
        MAIL:'E-mail',
        USERNAME:'Nom d\'utlisateur',
        NUMBER_C:'Nº Caisse',
        NUMBER_M:'Nº Marchand',
        RULE:'Rôle',
        ADD_USER:'Ajouter un utilisateur',
        ALL:'TOUS',
        USERS_WAIT:'En attente de validation',
        UPADATE:'Modifier',
        REGENERATE_PWD:'Regénérer mot de passe',
        REGENERATE_OPT:'Regénérer code otp',
        REGENERATE_PIN:'Regénérer code pin',
        C_REGENERATE_PWD:'Êtes-vous sûr de vouloir regénérer le mot de passe de cette utilisateur ?',
        C_REGENERATE_PIN:'Êtes-vous sûr de vouloir regénérer le code pin de cette utilisateur ?',
        C_REGENERATE_OPT:'Êtes-vous sûr de vouloir regénérer le code otp de cette utilisateur ?',
        ACTIVATE_USER:'Êtes-vous sûr de vouloir activé cet utilisateur ?',
        REJECT_USER:'Êtes-vous sûr de vouloir rejeté  cet utilisateur  ?',
        NO_USER:'Aucun utilisateur trouvé',
        EXPORT_USER:'export_users',
        ACTIVATE_USER_SUCCESS:'Compte activé avec succés!',
        REJECT_USER_SUCCESS:'Compte rejeté avec succés!',
      },
      PERMISSIONS: {
        PERMISSIONS:'Gestion permissions',
        PREVIOUS:'Précédent',
        NEXT:'Suivant',
        SUCCESS_UPDATE:'Permissions mise à jour avec succées',
        ERROR_UPDATE:'Erreur à la mise à jour des permissions',
        UPADATE_PERMISSIONS:'Mise à jour Permissions',
      },
      RULE:{
        RULE:'RÔLES',
        NAME:'Nom',
        STATUS:'Status',
        ADD_ROLE:'Ajouter un rôle',
        DELETE_ROLE:'Supprimer ce rôle',
        UPDATE_ROLE:'Modifier rôle',
        EDIT_ROLE:'Editer ce rôle',
        ADD_ROLE_SUCCESS:'Rôle créé avec succés',
        EDIT_ROLE_SUCCESS:'Rôle modifié avec succés',
        C_DELETE_ROLE:'Êtes-vous sûr vouloir supprimer ce rôle?',
      }
    },
    AGENCIES:{
      AGENCIES:{
        AGENCIES:'Agences',
        AGENCY_NAME:'Nom Agence',
        AGENCY_CODE:'Code Agence',
        STATE:'Etat',
        DIRECTORATE:'Direction',
        BALANCE:'Solde',
        CASH_BALANCE:'Solde Cash',
        ADDRESS:'Adresse',
        EDIT_AGENCIES:'Modifier une agence',
        SUP_AGENCY:'Agence supérieure',
        BALANCE_T:'Seuil Solde',
        HOURLY:'Horaire',
        OPENING:'Ouverture',
        CLOSING:'Fermeture',
        ADD_AGENCIES:'Ajouter agence',
        NO_AGENCIES:'Aucune agence trouvée',
        INITIAL_BALANCE:'Solde Inital',
        NUMBER_CRATE:'Nombre de caisse',

        //Initial balance


      },
      SUPPLIES:{
        SUPPLIES :'Approvisionnements',
        AGENCY_SUPPLIES :'Demandes d\'approvisonnement agences',
        START_DATE :'Date débbut',
        END_DATE :'Date fin',
        AGENCY :'Agence',
        AUTHOR :'Auteur',
        STATE :'Etat',
        BALANCE_B :'Solde avant',
        AMOUNT_RQ :'Montant Demandé',
        AMOUNT_GR :'Montant Accordé',
        BALANCE_A :'Solde après',
        VALIDATE_REJECT:'Validé / Rejeté par',
        PARENT :'Parent',
        DATE_RQ :'Date de demande',
        DATE_RS :'Date de réponse',
        DUPLICATE :'Duplicata',
        ON_HOLD :'En attente',
        GRANTED :'Accordée',
        REJECTED :'Rejetée',
        NO_SUPPLIES :'Aucune demande d\'approvisonnement trouvée',
        AMOUT_SUPPLIES :'Montant approvisionnement',
        REASONS :'Veuillez renseigner les motifs ',
        SUPPLIES_GRANTED :'Demande d\'approvisionnement accordée! ',
        SUPPLIES_REJECTED :'Demande d\'approvisionnement rejetée! ',
        EXPORT_S :'export_approvisionnements',
        TRY_AGAIN :'Veuillez réessayer plus tard',
        D_EXPORT :'approvisionnement_duplicata.pdf',
        E_D_EXPORT :'Echec export duplicata.Veuillez réessayer plus tard',

      }
    },
    CRATES:{
      CRATES:{
        CRATES:'Caisses',
        START_DATE :'Date débbut',
        END_DATE :'Date fin',
        AGENCY :'Agence',
        COOPERATIVES :'Coopératives',
        CODE_CRATE :'Code Caisse',
        CODE_MERCHANT :'Code Marchand',
        NUMBER_CRATE :'Numéro Caisse',
        NUMBER_MERCHANT :'Numéro Marchand',
        CODE :'Code',
        BALANCE:'Solde',
        CASH_BALANCE:'Solde Cash',
        DATE_CREATE:'Date création',
        LIST_AGENCIES:'Liste agences',
        LIST_COOPERATIVES:'Liste Coopératives',
        POINT_MERCHANT:'Point Marchand',
        AVAILABLE:'Disponible',
        AFFECTED:'Affecté',

        UPDATE_CRATE:'Modification de caisse',
        ADD_CRATE:'Ajout de caisse',
        ADD:'ajoutée!',
        EDIT:'modifié!',
        N_CRATE:'Caisse N°',


      }
    }
  },
};


