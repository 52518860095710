
// Français
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Choisissez votre langue',
    },

    GENERAL:{
      HOME:'Accueil',
      TOUS:'See everything',
      FOUND:'Found',
      LOGOUT:'Logout',
      PROFILE:'My profile',
      MONEY_TRANSFER:'Money transfer',
      OFFICE:'One stop shop',
      MASS_PAYMENT:'Mass payment',
      MASS_PAYMENT_S:'Payment of salary, aid, scholarships ...',
      WALLET:'Wallet',
      OPERATION_WALLET:'Cashin, Cashout, Airtime ...',
      BILL_PAYMENT:'Bill payment',
      BILL_PAYMENT_DESC:'Bill payment, water, electricity ...',
      TRANSACTIONS:'Transactions',
      SENDING:'Sending',
      WITHDRAWALS:'Withdrawals',
      AMOUNT:'Amount',
      EXPENSE:'Expense',
      COMMISSIONS:'Commissions',
      DEBIT_AMOUNT:'Debit amount',
      CREDITS_AMOUNT:'Credit amount',
      DAY:'Day',
      NUMBER:'Number',
      TOP_UP_BALANCE:'Top up my balance',
      BALANCE:'Balance',
      CASH_BALANCE:'Cash balance',
      AGENCY:'Agency',
      BALANCE_COM_ENT:'Balance Commission Entity',
      BALANCE_MERCHANT:'Merchant balance',
      COOPERATIVE:'Cooperative',
      CREATED_BY:'Created by',
      VALIDATE_BY:'Validate by',
      STATE:'State',
      SHARES:'Shares',
      CHOOSE:'--Choose--',
      ENABLLED:'Enabled',
      DISABLED:'Disabled',
      WAITING:'En attente',
      FILTERS:'On hold',
      DATE:'Date',
      CONFIRMATION:'CONFIRMATION',
      YES:'YES',
      NO:'NO',
      VALIDATE:'Validate',
      REJECT:'Reject',
      CRATE:'Crate',
      REGIONS:'Regions',
      DEPARTMENT:'Departments',
      MUNICIPALITIES:'municipalities',
    },

    AUTH: {
      LOGIN: {
        BUTTON: 'To log in',
        CONNECT : 'LOGIN',
        USERNAME : 'Username',
        PASSWORD: 'Password',
        LOAD_CONNECT: 'Please wait ...',
        ERROR_EN: 'Your account is not linked to an entity. Please contact the support service.',
        EN_NOTFOUND: 'Entity not found',
      },
      NOUVEAUPASSWORD: {
        TITLE: 'Set up a new password?',
        SOUSTITLE: 'Have you already reset your password?',
      },
      FORGOT: {
        TITLE: 'Forgot your password?',
        DESC: 'Enter your email to reset your password.',
        SUCCESS: 'Your account has been successfully reset.',
        DEMANDE: 'Your request has been sent successfully. You will receive an email to change your password.',
        ENREGISTER : 'Send',
        ANNULE : 'Cancel',
        ECHEC_TOKEN: 'Failure',
        ECHEC_TOKEN_MGS: 'Your session has expired Please try to regenerate your password again',
        LENGHTPWD: 'The password must contain at least 8 characters.',
        CONFIRM_FIELD_PWD:'The two passwords must be identical.',
        SET_CONNECT: 'New Password',
      },
    },
    BUTTONS:{
      ADD_BUTTON: 'Add',
      CANCEL_BUTTON : 'Cancel',
      UPDATE_BUTTON : 'To modify',
      DEACTIVATE_BUTTON : 'Disable',
      ACTIVATE_BUTTON : 'Enable',
      SUBMIT_BUTTON: 'Save',
      VALIDATE_BUTTON: 'To validate',
      CONFIRM_BUTTON: 'Confirm',
      FILTER: 'Filter',
      SEARCH: 'To research',
      EXPORT: 'Export',
      EXPORT_CSV: 'CSV',
      EXPORT_PDF: 'PDF',
      NEXT: 'Next',
      PREVIOUS: 'Previous',
      BACK: 'Back',
      CONTINUE: 'Continue',
        GALLERY: 'Gallery',
      DETAIL: 'Details',
      FINISH: 'To end',
    },
    SIDEBAR:{
      DASHBOARD:'Dashboard',
      STAFF:{
        STAFF: 'Staff',
        USERS:'Users',
        ROLES:'Roles',
        PERMISSIONS:'Permissions',
        POINT_LOCATION:'Location Of Points',
      },
      AGENCIES:{
        AGENCIES_S:'Points of sale',
        AGENCIES:'Agencies',
        SUPPLIES:'Supplies',
      },
      CRATES:{
        CRATES:'Crates',
        LIST:'List',
        SUPPLIES_CRATES:'Crate Supplies',
        MERCHANT_POINTS:'Merchant Points'
      },
      REPORTING:{
        REPORTING: 'Reporting',
        ALL:'All',
        RECEIPTS:'Receipts',
        DISBURSEMENT:'Disbursements',
        ACCOUNT_MONITORING:'Account monitoring',
        PHONE_ACCOUNT_BALANCE:'Phone Account Balance',
        CASH_STATUS:'Cash register situation',
        PHONE_SMS:'Telephone message',
      },

      DISTRIBUTORS:{
        DISTRIBUTORS:'Distributors'
      },
      COOPERATIVES:{
        COOPERATIVES:'Cooperatives',
        LIST_COOPERATIVES:'Cooperatives List',
        SUPPLIES:'Supplies',
      },
      MERCHANTS:{
        MERCHANTS:'Merchants',
        FILE_LOADING:'File loading',
        LIST_MERCHANTS:'Merchant list',
        LIST_CODE_MERCHANTS:'Merchant Code List',
        LIST_APPEAL:'Fundraising list',
        LIST_TRANSACTIONS:'Transaction list',
      },
      CUSTOMERS:{
        CUSTOMERS:'Customers',
        NEW_CUSTOMERS:'New customer',
        LIST_WAIT_CUSTOMERS:'Waiting List',
        UNCAPPING:'Removals'
      },
      PRODUCERS:{
        PRODUCERS:'Producers'
      },
      LOGS:{
        LOGS:'Logs'
      },
      MESSAGES:{
        MESSAGES:'Messages'
      },
      PAYMENTS:{
        PAYMENTS:'Deposit',
        NEW_PAYMENTS_B:'New bank payment',
        NEW_APPEAL:'New substantive call',
        NEW_PAYMENTS_A:'New Agency payment',
        LIST_PAYMENTS:'List of  Deposits',
      },
      TRANSFERS:{
        TRANSFERS:'Transfers',
        CANCELATION:'Cancelation',
        REFUND:'Repayment',
        TRANSFERS_FUND:'Funds transfer',
      },
      TRANSACTIONS:{
        TRANSACTIONS:'Transactions',
        LISTE:'Lists',
        TRS_WALLET:'Client wallet transactions',
        TRS_WAIT:'Pending Transactions',
        TRSF_UV:'UV transfer',
        SUBSTANTIVE_APPEAL:'Substantive appeal',
        SUBSTANTIVE_APPEAL_A:'Agency substantive appeal',
        TRS_SIM:'Simbot Transactions',
        TRS_PARTENERS:'Partner Transactions',
        TRS_LOTO:'Lotto Winning Transactions',
        TRS_INT:'Pending International Transactions',
        TRS_CASH:'Cash Transactions',
        TRS_OFFERS:'Telecom Transactions',

      },
      SUBSTANTIVE_APPEAL:{
        SUBSTANTIVE_APPEAL:'Substantive appeal',
        SUBSTANTIVE_CRATES:'Fund call',
        SUBSTANTIVE_AGENCIES:'Agency substantive appeal',

      },
      CARDS:{
        CARDS:'Cards',
        DISTRIBUTIONS_A:'Agency distributions',
        DISTRIBUTIONS_C:'Crate distributions',
        RECEPTIONS:'Receipt',
        PRICE_CARDS:'Price Cards',
        LISR_CARDS:'List of cards',
        BLOCKED_CARDS:'Blocked Cards',
        BACK_CARDS_C:'Return Crate Cards',
        BACK_CARDS_A:'Back Cards-Agency',
        RECEPTIONS_CARDS_A:'Cards-Agency Reception',
        SUPPLIES:'Supplies',
        RESEARCH_CARDS:'Search Card',
        REPORTING_CARDS:'Reporting',
      },
      CAMPAIGNS:{
        CAMPAIGNS:'Campaigns',
        LIST_CAMPAIGNS:'List Campaigns',
      },
      RESTRICTIONS:{
        RESTRICTIONS:'Limitations',
        ALL:'General',
        CARDS:'Card',
        TPE:'TPE',
        DAB:'DAB',
        WALLET:'Wallet',
      },
      RULES:{
        RULES:'Rules',
        LIST:'Lists',
        EVENTS:'Events'
      },
      ENROLLMENT:{
        ENROLLMENT:'Enrollment',
        NEW:'New',
        LIST:'Liste',
        NEW_PIECES_C:'New compliance part',
        LIST_PIECES_C:'Compliance part list',
      },
      TRACKERS:{
        TRACKERS:'Trackers',
        LIST_TRACKERS:'Tracker List',
        SUPPLIES:'Supplies',
        SUBSTANTIVE_APPEAL:'Substantive appeal',
      },
      CASH_SUPPLY:{
        CASH_SUPPLY:'Cash supply',
        CASH_SUPPLY_A:'Cash supply agency',
        CASH_SUPPLY_C:'Cash supply crate'
      },
      ITEMS:{
        ITEMS:'Items',
        lIST_DISTRIBUTION:'Distribution list',
        lIST_BOOKLETS:'List of booklets',
        NEW_DISTRIBUTIONS_A:'New agency distribution',
        NEW_DISTRIBUTIONS_C:'New crate distribution',
        INVENTOR_DISTRIBUTION:'Stock distribution',
      }

    },
    DASHBOARD:{
      ACTIVITIES:'Activities',
      DASHBOARD:'Dashboard',
      TODAY:'Today',
      START:'Start:',
      END:'End:',
      AGENCIES:'Agencies',
      COOPERATIVES:'Cooperatives',
      SERVICES:'Services',
      NUMBER_TRS:'Number of transactions',
      AMOUNT_TRS:'Transaction amount',
      NUMBER_SUPPLIES:'Supply Number',
      AMOUNT_SUPPLIES:'Amount of supplies',
      DEBITS:'Debits',
      AMOUNT_DEBITS:'Debits Amount',
      CREDITS:'Credits',
      AMOUNT_CREDITS:'Credits amount',
      AMOUNT_SERVICES:'Commission amount',
      NUMBER_COOPERATIVES:'Number of cooperative',
      NUMBER_AGENCIES:'Number of agency',
      AMOUNT_COOPERATIVES:'Amount allocated to cooperatives',
      AMOUNT_AGENCIES:'Amount allocated to agencies',
      AMOUNT_OM:'Orange money sale',
      BALANCE:'Overall balance',
      BALANCE_AGENCIES:'Overall balance of my agency',
      NUMBER_C:'Number of crates',
      AMOUNT_C:'Amount allocated to the crates',
      MY_SUBSCRIPTION:'My subscriptions',
      BEST_AGENCIES:'Best Agencies',
      BEST_COOPERATIVES:'Best cooperatives',
      BEST_SERVICES:'Best services',
      RANKING_SERVICES:'Ranking of the best services',
      RANKING_BEST:'Ranking of the best',
      BY_TRS_AMOUNT:'by transaction amount',
      COMMISSIONS_MONTH:'Commissions of the month',
      TRANSACTION_MONTH:'Transaction of the month',
      BEST_CRATES:'Best crates',
      BEST_POINT:'Best Market Points',
      POINT_MERCHANT:'Merchant Point',
      CRATES:'Crates',
      COMMISSION_CRATES:'Cash commission',
      TOTAL_COMMISSION_CRATES:'Total Commissions',
      DISTRIBUTORS:'Distributors',
      BEST_DISTRIBUTORS:'Best Distributors',
      COMMISSIONS_DISTRIBUTORS:'Distributor Fees',
    },
    STAFF:{
      USERS:{
        USERS:'Users',
        FULLNAME:'First name & last name',
        LASTNAME:'last name',
        FIRSTNAME:'First name',
        PHONE:'Phone',
        MAIL:'E-mail',
        USERNAME:'Username',
        NUMBER_C:'Nº Crates',
        NUMBER_M:'Nº Merchant',
        RULE:'Rule',
        ADD_USER:'Add user',
        ALL:'All',
        USERS_WAIT:'Waiting for validation',
        UPADATE:'Update',
        REGENERATE_PWD:'Regenerate password',
        REGENERATE_OPT:'Regenerate otp code',
        REGENERATE_PIN:'Regenerate pin code',
        C_REGENERATE_PWD:'Are you sure you want to regenerate this user\'s password?',
        C_REGENERATE_PIN:'Are you sure you want to regenerate this user\'s pin code?',
        C_REGENERATE_OPT:'Are you sure you want to regenerate this user\'s otp code?',
        ACTIVATE_USER:'Are you sure you want to activate this user?',
        REJECT_USER:'Are you sure you want to reject this user?',
        NO_USER:'No users found',
        EXPORT_USER:'export_users',
        ACTIVATE_USER_SUCCESS:'Account activated successfully!',
        REJECT_USER_SUCCESS:'Account successfully rejected!',
      },
      PERMISSIONS: {
        PERMISSIONS:'Permission management',
        PREVIOUS:'Previous',
        NEXT:'Next',
        SUCCESS_UPDATE:'Permissions updated successfully',
        ERROR_UPDATE:'Error updating permissions',
        UPADATE_PERMISSIONS:'Update Permissions',
      },

      RULE:{
        RULE:'Rule',
        NAME:'Name',
        STATUS:'Status',
        ADD_ROLE:'Add a role',
        DELETE_ROLE:'Delete this role',
        UPDATE_ROLE:'Update role',
        EDIT_ROLE:'Edit this role',
        ADD_ROLE_SUCCESS:'Role created successfully',
        EDIT_ROLE_SUCCESS:'Role changed successfully',
        C_DELETE_ROLE:'Are you sure you want to delete this role?',
      }
    },
    AGENCIES:{
      AGENCIES:{
        AGENCIES:'Agencies',
        AGENCY_NAME:'Name Agency',
        AGENCY_CODE:'Agency code',
        STATE:'State',
        DIRECTORATE:'Directorate',
        BALANCE:'Balance',
        CASH_BALANCE:'Cash balance',
        ADDRESS:'Address',
        EDIT_AGENCIES:'Edit an agency',
        SUP_AGENCY:'Superior agency',
        BALANCE_T:'Balance Threshold',
        HOURLY:'Hourly',
        OPENING:'Opening',
        CLOSING:'Closing',
        ADD_AGENCIES:'Add agency',
        NO_AGENCIES:'No agency found',
        INITIAL_BALANCE:'Initial balance',
        NUMBER_CRATE:'Number of crate',
    },
      SUPPLIES:{
        SUPPLIES :'Supplies',
        AGENCY_SUPPLIES :'Agency procurement requests',
        START_DATE :'Start date',
        END_DATE :'End date',
        AGENCY :'Agency',
        AUTHOR :'Author',
        STATE :'State',
        BALANCE_B :'Balance before',
        AMOUNT_RQ :'Amount requested',
        AMOUNT_GR :'Amount Granted',
        BALANCE_A :'balance after',
        VALIDATE_REJECT :'Validated / Rejected by',
        PARENT :'Parent',
        DATE_RQ :'Date of request',
        DATE_RS :'Response date',
        DUPLICATE :'Duplicate',
        ON_HOLD :'On hold',
        GRANTED :'Granted',
        REJECTED :'Rejected',
        NO_SUPPLIES :'No requisitions found',
        AMOUT_SUPPLIES :'Supply amount',
        REASONS :'Please provide reasons ',
        SUPPLIES_GRANTED :'Supply request granted! ',
        SUPPLIES_REJECTED :'Procurement request rejected! ',
        EXPORT_S :'export_supplies',
        TRY_AGAIN :'Please try again later',
        D_EXPORT :'supply_duplicate.pdf',
        E_D_EXPORT :'Duplicate export failed. Please try again later',
      }

    },
    CRATES:{
      CRATES:{
        CRATES:'Crates',
        START_DATE :'Start date',
        END_DATE :'End date',
        AGENCY :'Agency',
        COOPERATIVES :'Cooperatives',
        CODE_CRATE :'Crate code',
        CODE_MERCHANT :'Merchant Code',
        NUMBER_CRATE :'Crate Number',
        NUMBER_MERCHANT :'Merchant Number',
        CODE :'Code',
        BALANCE:'Balance',
        CASH_BALANCE:'Cash balance',
        DATE_CREATE:'Creation Date',
        LIST_AGENCIES:'List agencies',
        LIST_COOPERATIVES:'List Cooperatives',
        POINT_MERCHANT:'Merchant Point',
        AVAILABLE:'Available',
        AFFECTED:'Affected',
        UPDATE_CRATE:'Crate modification',
        ADD_CRATE:'Adding crate',
        ADD:'added!',
        EDIT:'modified!',
        N_CRATE:'Crate N°',
      }
    }
  },
};
