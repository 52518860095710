export class UserPermissions {
  static MANAGE_AGENCIES = {
    module: 'pointsdevente',
    actions: {
      viewAgencies: 'pointsdevente_voir',
      viewDist: 'pointsdevente_liste_distributeurs',
      addDist: 'pointsdevente_ajouter_distributeur',
      viewAppros: 'pointsdevente_voir_approcisionnamants',
      createAgence: 'pointsdevente_creer_agence',
      editAgence: 'pointsdevente_modifier_agence',
      deleteAgence: 'pointsdevente_supprimer_agence',
      exportPdfAgence: 'pointsdevente_export_pdf',
      exportExcelAgence: 'pointsdevente_export_excel',
      exportPdfAprosAgence: 'pointsdevente_export_pdf_apros_agence',
      exportExcelAprosAgence: 'pointsdevente_export_excel_apros_agence',
      aproveDemandeAproAg: 'pointsdevente_accepter_demande_appros',
      rejectDemandeAproAg: 'pointsdevente_rejeter_demande_appros'
    }
  };
  static MANAGE_COOPERATIVE = {
    module: 'cooparative',
    actions: {
      viewcooparative: 'cooparative_lister_cooparative',
      createcooparative: 'cooparative_ajouter_cooparative',
      approcooparative: 'cooparative_approvissionnements',
      validerappro: 'cooparative_valider_appro',
      rejeterappro: 'cooparative_rejeter_appro',
    }
  };
  static MANAGE_PISTEUR = {
    module: 'pisteur',
    actions: {
      viewpisteur: 'pisteur_liste_des_pisteurs',
      viewappropisteur: 'pisteur_appro_pisteur',
      viewreverspisteur: 'pisteur_appel_de_fond_pisteur',
      validerapproPisteur: 'pisteur_valider_appro',
      rejeterapproPisteur: 'pisteur_rejeter_appro',
      validerreversementPisteur: 'pisteur_valider_appel_de_fond',
      rejeterreversementPisteur: 'pisteur_rejeter_appel_de_fond',
      approvisionnerPisteur: 'pisteur_approvisionner_un_pisteur',
      reversementdefond: 'pisteur_reversement_de_fond',
    }
  };
  static MANAGE_USERS = {
    module: 'utilisateurs',
    actions: {
      viewUsers: 'utilisateurs_voir',
      editUser: 'utilisateurs_modifier',
      deleteUser: 'utilisateurs_supprimer',
      createUser: 'utilisateurs_creer',
      viewPermissions: 'utilisateurs_voir_permissions',
      assignPermission: 'utilisateurs_assigner permissions',
      activateUser: 'utilisateurs_valider_utilisateur',
      viewRoles: 'utilisateurs_voir_roles',
      createRoles: 'utilisateurs_craer_role',
      editRoles: 'utilisateurs_modifier_role',
      liste_en_attente: 'utilisateurs_liste_en_attente',
      deleteeRoles: 'utilisateurs_supprimer_role',
      regenerePassword: 'utilisateurs_regenerer_mot_de_passe',
      regenerePin: 'utilisateurs_regenerer_pin',
      exportPdf: 'utilisateurs_export_pdf',
      exportExcel: 'utilisateurs_export_excel',
      lacation: 'utilisateurs_localisation_des_points',

    }
  };
  static MANAGE_REPORTING = {
    module: 'reporting',
    actions: {
      viewTransactions: 'raporting_voir_transactions',
      viewAll: 'raporting_voir_toos',
      viewCredits: 'raporting_voir_encaissamants',
      viewDebits: 'raporting_voir_dacaissamants',
      followAccounts: 'raporting_soivi_comptas',
      exportExcel: 'raporting_export_excel_transaction',
      exportPdf: 'raporting_export_pdf_transaction',
      viewRefundTransaction: 'raporting_remboursement_transaction',
      reportingCarte: 'reporting_reporting_carte',
      soldesTelephone: 'reporting_solde_talaphone',
      createTelephone: 'reporting_solde_talaphone',
      messageTelephone: 'reporting_message_talaphone',
      situationCaisse: 'reporting_situation_caisse',
    }
  };
  static MANAGE_WALLET = {
    module: 'wallat',
    actions: {
      viewTransactions: 'wallat_voir_transactions',
      viewClients: 'wallat_voir_cliants',
      addClients: 'wallat_nouveau_client',
      viewLimitations: 'wallat_voir_limitations',
      exportClientExcel: 'wallat_export_excel_client',
      exportClientPdf: 'wallat_export_pdf_client',
      blockClient: 'wallat_bloquer_client',
      activeClient: 'wallat_activer_client',
      deplafondClient: 'wallat_deplafonnement_client',
      viewPointM: 'wallat_liste_points_marchands',
      newPointM: 'wallat_nouveau_point_marchand',
      editclient: 'wallat_modifier',
    }
  };
  static MANAGE_LOGS = {
    module: 'logs',
    actions: {
      viewLogs: 'logs_voir_toos',
      viewMessages: 'logs_voir_massagas',
      createMessage: 'logs_ajouter_message',
      editMessage: 'logs_modifier_message',
      exportPdfLogs: 'logs_export_pdf_logs',
      exportExcelLogs: 'logs_export_excel_logs',

    }
  };
  static MANAGE_MESSAGES = {
    module: 'messages',
    actions: {
      viewMessages: 'messages_voir_message',
      createMessage: 'messages_ajouter_message',
      editMessage: 'messages_modifier_message'
    }
  };
  static MANAGE_TRANSFERS = {
    module: 'transferts',
    actions: {
      viewCancels: 'transferts_voir_annulations',
      viewRefunds: 'transferts_voir_remboursement',
      rejeterAn: 'transferts_rejeter_une_annulation',
      validerAn: 'transferts_valider_annulations',
      rejeterRm: 'transferts_rejeter_remboursement',
      validerRm: 'transferts_valider_remboursement',
      viewFundsTransfers: 'transactions_transferts_de_fonds',
    }
  };
  static MANAGE_CAISSES = {
    module: 'caisses',
    actions: {
      view: 'caisses_voir',
      viewApprovs: 'caisses_approvisionnements',
      createCaisse: 'caisses_creer_caisse',
      createMarchand: 'caisses_craer_marchand',
      viewMarchands: 'caisses_liste_marchand',
      updateMarchands: 'caisses_modifier_marchand',
      // edittCaisse:'caisses_modifier_caisse',
      // deleteCaisse:'caisses_supprimer_caisse',
      expotPdfCaisse: 'caisses_export_pdf_caisse',
      expotExcelCaisse: 'caisses_export_excel_caisse',
      modificationCaisse: 'caisses_modification_caisse',
      // exp
      aproveDemandeApro: 'caisses_accepter_demande_appros',
      rejectDemandeApro: 'caisses_rejeter_demande_appros',
      expotPdfApproCaisse: 'caisses_export_pdf_appros',
      expotExcelAproCaisse: 'caisses_export_excel_appros',
    }
  };
  static MANAGE_CARDS = {
    module: 'cartes',
    actions: {
      view: 'cartes_voir',
      agencesDistrib: 'cartes_distributions_agences',
      caissesDistrib: 'cartes_distributions_caisse',
      retourAgence: 'cartes_retour_agence',
      retourCaisse: 'cartes_retour_caisse',
      received: 'cartes_raceptions',
      cardsPrices: 'cartes_prix_cartes',
      availableCards: 'cartes_cartes_disponibles',
      approvs: 'cartes_approvisionnements',
      receivedAgence: 'cartes_raceptions_agence',
      search: 'cartes_recherche',
      reporting: 'cartes_voir_reporting',
      cartesbloquees: 'cartes_liste_des_cartes_bloquaes',
      createDistributionAgence: 'cartes_ajout_distribution_agence',
      createDistributionCaisse: 'cartes_ajout_distribution_caisse',
      receptionCardAgence: 'cartes_raception_cartes_agences',
      receptionCardAgenceRetour: 'cartes_raception_retour_cartes_agences',
      receptionCardCaisseRetour: 'cartes_raception_retour_cartes_caisses',
      rejetCardAgence: 'cartes_rejeter_distribution_cartes_agences',
      rejetCardAgenceRetour: 'cartes_rejeter_retour_cartes_agences',
      rejetCardCaisseRetour: 'cartes_rejeter_retour_cartes_caisses',
      receptionCardEntity: 'cartes_raception_cartes_entitas',
      editPrice: 'cartes_modifier_prix_cartes_',
      rejetCardEntity: 'cartes_rejeter_raception_cartes_entites',
      exportPdf: 'cartes_export_cartes_pdf',
      exportExcel: 'cartes_export_cartes_csv',
      demandeApproCarteAgence: 'cartes_demande_appro_cartes_agences',
      demandeApproCarteEntite: 'cartes_demande_appro_cartes_entite',
      soldeCartes: 'cartes_solde_cartes',
    }
  };
  static MANAGE_REGLES = {
    module: 'regles',
    actions: {
      viewRegles: 'regle_liste_des_regles',
      createRegle: 'regles_creer_une_regle',
      editRegle: 'regles_modifier_une_regle'
    }
  };
  static MANAGE_REVERSEMENT={
    module: 'reversement',
    actions: {
      appeldefond: 'reversement_liste_appel_de_fonds_caisse',
      appeldefondAg: 'reversement_liste_appel_de_fonds_agence',
      addappeldefondAg: 'reversement_demande_appel_de_fonds_agence',
      rejeterAppeldefond: 'reversement_rejeter_appel_de_fonds',
      validerAppeldefond: 'reversement_accepter_appel_de_fonds',
      validerAppeldefond_A: 'reversement_accepter_appel_de_fond_agence',
      rejeterAppeldefond_A: 'reversement_rejeter_appel_de_fond_agence',
    }
}
  static MANAGE_TRANSACTIONS = {
    module: 'transactions',
    actions: {
      viewTransactions: 'transaction_lister',
      viewTransactionsOffers: 'transaction_lister',
      viewTransactionsPending: 'transactions_lister_les_transactions_en_attente',
      viewTransactionsCltW: 'transactions_liste_transactions_client_wallet',
      transfertUV: 'transactions_transfert_uv',
      viewTransactionSimbot: 'transactions_lister_les_transactions_simbot',
      annulerTransactionSimbot: 'transactions_annuler_transaction_simbot',
      ajoutTransfertUV: 'transactions_faire_un_transfert_uv',
      appeldefond: 'transactions_appel_de_fonds',
      appeldefondAg: 'transactions_liste_appel_de_fonds_agence',
      addappeldefondAg: 'transactions_demande_appel_de_fonds_agence',
      rejeterAppeldefond: 'transactions_rejeter_un_appel_de_fond',
      validerAppeldefond: 'transactions_valider_un_appel_de_fond',
      viewTransactionPartener: 'transactions_transactions_partenaires',
      viewTransactionGain: 'transactions_liste_des_gain_loto',
      viewTransactionInt: 'transactions_transfert_international_en_attente',
      viewtransactionsCash: 'transactions_transactions_cash',
      validerTransactionInt: 'transactions_valider_transfert_international',
      rejeterTransactionInt: 'transactions_rejeter_transfert_international',
      addGain: 'transactions_nouveau_gain_loto',
      validerGain: 'transactions_valider_gain_loto',
      rejeterGain: 'transactions_rejeter_gain_loto',
    }
  };
  static MANAGE_VERSEMENTS = {
    module: 'versements',
    actions: {
      viewVersements: 'versements_liste_des_versements',
      addVersementsB: 'versements_ajouter_versement_banque',
      addAppeldefond: 'versements_appel_de_fond',
      rejeterVersement: 'versements_rejeter_versement',
      validerVersement: 'versements_valider_versement',
      addVersementAgence: 'versements_ajouter_versement_agence',
      viewVersementsCom: 'versements_liste_des_versements',
    }
  };
  static LONASE_ACTIONS_RESTRICT = [
    {
      code: 'cartes_distributions_caisse'

    },
    {
      code: 'cartes_retour_caisse'
    },
    {
      code: 'cartes_ajout_distribution_caisse'
    },
    {
      code: 'cartes_raception_retour_cartes_caisses'
    },
    {
      code: 'cartes_rejeter_retour_cartes_caisses'
    }
  ];
  static AGRIDEV_ACTIONS_RESTRICT = [
    {
      code: 'transactions_transfert_uv'

    },
    {
      code: 'transactions_lister_les_transactions_simbot'
    },
    {
      code: 'transactions_annuler_transaction_simbot'
    },
    {
      code: 'transactions_faire_un_transfert_uv'
    },
    {
      code: 'transactions_appel_de_fonds'
    },
    {
      code: 'transactions_rejeter_un_appel_de_fond'
    },
    {
      code: 'transactions_valider_un_appel_de_fond'
    },
    {
      code: 'reporting_message_talaphone'
    },
    {
      code: 'reporting_solde_talaphone'
    },
    {
      code: 'raporting_remboursement_transaction'
    },
    {
      code: 'raporting_soivi_comptas'
    },
    {
      code: 'reporting_reporting_carte'
    }
  ];
  static AGRIDEV_MODULES_RESTRICT = [
    {
      codeModule: 'pointsdevente'
    },{
      codeModule: 'cartes'
    },{
      codeModule: 'logs'
    },{
      codeModule: 'transferts'
    },{
      codeModule: 'limitation_vitesse'
    },{
      codeModule: 'enrilement_caissier'
    },
    ]


  static MANAGE_LIMITATION = {
    module: 'limitation_vitesse',
    actions: {
      viewlimitCarte: 'limitation_vitesse_liste_limite_cartes',
      updatelimitCarte: 'limitation_vitesse_modifier_limite_carte',
      viewlimitTpe: 'limitation_vitesse_liste_limite_tpe',
      updatelimitTpe: 'limitation_vitesse_modifier_limite_tpe',
      viewlimitDab: 'limitation_vitesse_liste_limite_dab',
      updatelimitDab: 'limitation_vitesse_modifier_limite_dab',
      viewlimitWallet: 'limitation_vitesse_liste_limite_wallet',
      updatelimitWallet: 'limitation_vitesse_modifier_limite_wallet',
      viewlimitGeneral: 'limitation_vitesse_liste_limite_ganaral',
      updatelimitGeneral: 'limitation_vitesse_modifier_limite_ganaral',
    }
  };
  static MANAGE_ENROLEMENT = {
    module: 'enrilement_caissier',
    actions: {
      create: 'enrilement_caissier_nouveau_enrilement',
      list: 'enrilement_caissier_liste_des_enrilements',
      createPiece: 'enrilement_caissier_complater_les_piaces_de_conformita_',
      listPiece: 'enrilement_caissier_liste_des_piaces_de_conformita',
      validerPiece: 'enrilement_caissier_valider_piace',
      rejeterPiece: 'enrilement_caissier_rejeter_piace',
    }
  };
  static MANAGE_APPROVISIONNEMENT = {
    module: 'approvisionnement',
    actions: {
      appro_entite: 'approvisionnement_approvisionnement_entita',
      appro_comm_entite: 'approvisionnement_commission_entite',
      appro_agence: 'approvisionnement_approvisionnement_agence',
      solde_cash: 'approvisionnement_solde_cash',
      solde_cashAn: 'approvisionnement_solde_cash_agence',
    }
  };
  static MANAGE_DASHBOARD = {
    module: 'tableau_de_bord',
    actions: {
      viewDashboard: 'consulter_tableau_de_bord',
      viewMCaisse: 'tableau_de_bord_meilleurs_caisses',
      viewMDist: 'tableau_de_bord_meilleurs_distributeurs',
      viewMMarchand: 'tableau_de_bord_meilleurs_marchands',
      viewMAg: 'tableau_de_bord_meilleurs_agences',
      viewMS: 'tableau_de_bord_meilleurs_services',
      viewCM: 'tableau_de_bord_commission_du_mois',
      viewTM: 'tableau_de_bord_transaction_du_mois',
      viewAgence: 'tableau_de_bord_detail_agence',
      viewService: 'tableau_de_bord_detail_service',
      viewActivite: 'tableau_de_bord_detail_activite',
    }
  };
  static MANAGE_CAMPAGNE = {
    module: 'campagnes',
    actions: {
      createCampagne: 'campagnes_craer_campagne',
      viewCampagne: 'campagnes_voir_campagne',
      updateCampagne: 'campagnes_modifier_campagne',
    }
  };
  static MANAGE_ARTICLESS = {
    module: 'articles',
    actions: {
      viewDistributionAg: 'articles_liste_distributions',
      newDistributionAg: 'articles_distribution_agences',
      newDistributionAc: 'articles_distribution_caisses',
      validerDistributionAc: 'articles_valider_distribution_caisse',
      validerDistributionAg: 'articles_valider_distribution_agence',
      rejeterDistributionAg: 'articles_rejeter_distribution_agence',
      rejeterDistributionAc: 'articles_rejeter_distribution_caisse',
      viewStock: 'articles_rapartitions_stock',
      viewArticles: 'articles_liste_des_articles',
      ajouterArticle: 'articles_ajouter_article',
      newDistribution: 'articles_distribution_entitas',
    }
  };
  static MANAGE_MARCHANDS = {
    module: 'marchands',
    actions: {
      createMarchand: 'marchands_nouveau_marchand',
      viewMarchand: 'marchands_liste_marchands',
      viewCodeMarchand: 'marchands_liste_codes_marchands',
      uploadMarchand: 'marchands_charger_fichier_code_marchand',
      updateMarchand: 'marchands_modifier_marchand',
      viewAppelFond: 'marchands_liste_appel_de_fond',
      demandeAppelFond: 'marchands_demande_appel_de_fond',
      rejetAppelFond: 'marchands_rejeter_appel_de_fond',
      accepterAppelFond: 'marchands_accepter_appel_de_fond',
      viewTransactions: 'marchands_liste_transactions',
      solde_marchand: 'marchands_solde',
    }
  };
  static MANAGE_APPROVISIONNEMENTCASH = {
    module: 'approvisionnement_cache',
    actions: {
      validerApprocashAgence: 'approvisionnement_cache_valider_appro_cache_agence',
      validerApprocashCaisse: 'approvisionnement_cache_valider_appro_cache_caisse',
      viewapprocashAgence: 'approvisionnement_cache_liste_des_appro_caches_agence',
      viewapprocashCaisse: 'approvisionnement_cache_liste_des_appro_caches_caisse',
      addapprocashAgence: 'approvisionnement_cache_ajouter_appro_cache_agence',
      addapprocashCaisse: 'approvisionnement_cache_ajouter_appro_cache_caisse',
      rejeterApprocashAgence: 'approvisionnement_cache_rejeter_appro_cache_agence',
      rejeterApprocashCaisse: 'approvisionnement_cache_rejeter_appro_cache_caisse',
    }
  };
  static MANAGE_DISTRIBUTEURS = {
    module: 'distributeurs',
    actions: {
      viewDistributeurs: 'distributeurs_liste_distributeurs',
      addDistributeurs: 'distributeurs_ajouter_distributeur',

    }
  };
}
