
// Français
export const locale = {
  lang: 'pr',
  data: {
    TRANSLATOR: {
      SELECT: 'Escolha seu idioma',
    },

    GENERAL:{
      HOME:'Bem-vindo',
      TOUS:'Veja tudos',
      FOUND:'encontrado',
      LOGOUT:'Desconectar',
      PROFILE:'Meu perfil',
      MONEY_TRANSFER:'Transferência de dinheiro',
      OFFICE:'Balcão único',
      MASS_PAYMENT:'Pagamento em massa',
      MASS_PAYMENT_S:'Pagamento de salário, auxílio, bolsas ...',
      WALLET:'Carteira',
      OPERATION_WALLET:'DEPÓSITO, RETIRADA, COMPRA DE CRÉDITO ...',
      BILL_PAYMENT:'Pagamento de conta',
      BILL_PAYMENT_DESC:'Pagamento de contas, água, luz ...',
      TRANSACTIONS:'Transações',
      SENDING:'Remessas',
      WITHDRAWALS:'Retiradas',
      AMOUNT:'Quantia',
      EXPENSE:'custos',
      COMMISSIONS:'Tarifas',
      DEBIT_AMOUNT:'Valor do débito',
      CREDITS_AMOUNT:'Valor de crédito',
      DAY:'Dia',
      NUMBER:'Número',
      TOP_UP_BALANCE:'Recarregar meu saldo',
      BALANCE:'Saldo',
      CASH_BALANCE:'Saldo de caixa',
      AGENCY:'Agência',
      BALANCE_COM_ENT:'Entidade Comissão de Saldo',
      BALANCE_MERCHANT:'Saldo do comerciante',
      COOPERATIVE:'Cooperativo',
      CREATED_BY:'Criado por',
      VALIDATE_BY:'Validado por',
      STATE:'Estado',
      SHARES:'Ações',
      CHOOSE:'--Escolher--',
      ENABLLED:'Habilitado',
      DISABLED:'Desabilitado',
      WAITING:'em espera',
      FILTERS:'Filtros',
      DATE:'Data',
      CONFIRMATION:'CONFIRMAÇÃO',
      YES:'SIM',
      NO:'NÃO',
      VALIDATE:'Validar',
      REJECT:'Liberar',
      CRATE:'Caixas',
      REGIONS:'Regiões',
      DEPARTMENT:'Departamentos',
      MUNICIPALITIES:'municípios',
    },

    AUTH: {
      LOGIN: {
        BUTTON: 'Conectar',
        CONNECT : 'CONEXÃO',
        USERNAME : 'Nome de usuário',
        PASSWORD: 'Senha',
        LOAD_CONNECT: 'Conectando ...',
        ERROR_EN: 'Sua conta não está vinculada a uma entidade. Entre em contato com o serviço de suporte.',
        EN_NOTFOUND: 'Entidade não encontrada',
      },
      NOUVEAUPASSWORD: {
        TITLE: 'Configurar uma nova senha?',
        SOUSTITLE: 'Você já redefiniu sua senha?',
      },
      FORGOT: {
        TITLE: 'Esqueceu sua senha?',
        DESC: 'Insira seu e-mail para redefinir sua senha',
        SUCCESS: 'Sua conta foi redefinida com sucesso.',
        DEMANDE: 'Sua solicitação foi enviada com sucesso. Você receberá um e-mail para alterar sua senha.',
        ENREGISTER : 'Enviar',
        ANNULE : 'Cancelar',
        ECHEC_TOKEN: 'Falha',
        ECHEC_TOKEN_MGS: 'A sua sessão expirou Por favor tente regenerar a sua senha novamente',
        LENGHTPWD: 'A senha deve conter pelo menos 8 caracteres.',
        CONFIRM_FIELD_PWD:'As duas senhas devem ser idênticas.',
        SET_CONNECT: 'Nova Senha',
      },
    },
    BUTTONS:{
      ADD_BUTTON: 'Adicionar',
      CANCEL_BUTTON : 'Cancelar',
      UPDATE_BUTTON : 'Para modificar',
      DEACTIVATE_BUTTON : 'Desativar',
      ACTIVATE_BUTTON : 'Habilitar',
      SUBMIT_BUTTON: 'Salvar',
      VALIDATE_BUTTON: 'Para validar',
      CONFIRM_BUTTON: 'confirme',
      FILTER: 'Filtro',
      SEARCH: 'Pesquisar',
      EXPORT: 'Exportar',
      EXPORT_CSV: 'XLS',
      EXPORT_PDF: 'PDF',
      NEXT: 'Seguindo',
      PREVIOUS: 'Anterior',
      BACK: 'Voltar',
      CONTINUE: 'Continuar',
      GALLERY: 'Galeria',
      DETAIL: 'Detalhe',
      FINISH: 'Para terminar',
    },
    SIDEBAR:{
      DASHBOARD:'Painel',
      STAFF:{
        STAFF:'Funcionários',
        USERS:'Usuários',
        ROLES:'Funções',
        PERMISSIONS:'Permissões',
        POINT_LOCATION:'Localização dos pontos',
      },
      AGENCIES:{
        AGENCIES_S:'Pontos de venda',
        AGENCIES:'Agências',
        SUPPLIES:'Suprimentos',
      },
      CRATES:{
        CRATES:'Caixas',
        LIST:'Lista',
        SUPPLIES_CRATES:'Suprimentos de caixa',
        MERCHANT_POINTS:'Pontos de Comerciante'
      },
      REPORTING:{
        REPORTING: 'Comunicando',
        ALL:'Todos',
        RECEIPTS:'Recibos',
        DISBURSEMENT:'Desembolsos',
        ACCOUNT_MONITORING:'Monitoramento de conta',
        PHONE_ACCOUNT_BALANCE:'Saldo da conta telefônica',
        CASH_STATUS:'Situação da caixa registradora',
        PHONE_SMS:'Mensagem telefônica',
      },

      DISTRIBUTORS:{
        DISTRIBUTORS:'Distribuidores'
      },
      COOPERATIVES:{
        COOPERATIVES:'Cooperativas',
        LIST_COOPERATIVES:'Lista de Cooperativas',
        SUPPLIES:'Suprimentos',
      },
      MERCHANTS:{
        MERCHANTS:'Comerciantes',
        FILE_LOADING:'Carregando arquivo',
        LIST_MERCHANTS:'Lista de comerciantes',
        LIST_CODE_MERCHANTS:'Lista de códigos do comerciante',
        LIST_APPEAL:'Lista de arrecadação de fundos',
        LIST_TRANSACTIONS:'Lista de transações',
      },
      CUSTOMERS:{
        CUSTOMERS:'Clientes',
        NEW_CUSTOMERS:'Novo cliente',
        LIST_WAIT_CUSTOMERS:'Lista de espera',
        UNCAPPING:'Remoções'
      },
      PRODUCERS:{
        PRODUCERS:'Produtores'
      },
      LOGS:{
        LOGS:'Histórica'
      },
      MESSAGES:{
        MESSAGES:'Mensagens'
      },
      PAYMENTS:{
        PAYMENTS:'Pagamentos',
        NEW_PAYMENTS_B:'Novo pagamento bancário',
        NEW_APPEAL:'Nova chamada substantiva',
        NEW_PAYMENTS_A:'Novo pagamento de agência',
        LIST_PAYMENTS:'Lista de Pagamentos',
      },
      TRANSFERS:{
        TRANSFERS:'Transferências',
        CANCELATION:'Cancelamento',
        REFUND:'Reembolso',
        TRANSFERS_FUND:'Transferência de fundos',
      },
      TRANSACTIONS:{
        TRANSACTIONS:'Transações',
        LISTE:'Listas',
        TRS_WALLET:'Transações de carteira do cliente',
        TRS_WAIT:'Transações pendentes',
        TRSF_UV:'Transferência UV',
        SUBSTANTIVE_APPEAL:'Apelo substantivo',
        SUBSTANTIVE_APPEAL_A:'Apelo substantivo da agência',
        TRS_SIM:'Transações Simbot',
        TRS_PARTENERS:'Transações de parceiros',
        TRS_LOTO:'Transações ganhadoras de loteria',
        TRS_INT:'Transações Internacionais Pendentes',
        TRS_CASH:'Transações em dinheiro',
        TRS_OFFERS:'Transações de telecomunicações',

      },
      SUBSTANTIVE_APPEAL:{
        SUBSTANTIVE_APPEAL:'Apelo substantivo',
        SUBSTANTIVE_CRATES:'Chamada de fundos',
        SUBSTANTIVE_AGENCIES:'Apelo substantivo da agência',

      },
      CARDS:{
        CARDS:'CARTÕES',
        DISTRIBUTIONS_A:'Distribuições de agências',
        DISTRIBUTIONS_C:'Distribuições em dinheiro',
        RECEPTIONS:'Recepções',
        PRICE_CARDS:'Cartões de preços',
        LISR_CARDS:'Lista de cartas',
        BLOCKED_CARDS:'Cartões bloqueados',
        BACK_CARDS_C:'Devolver cartões de dinheiro',
        BACK_CARDS_A:'Voltar Cartões-Agência',
        RECEPTIONS_CARDS_A:'Recepção de Cartões-Agência',
        SUPPLIES:'Suprimentos',
        RESEARCH_CARDS:'Cartão de pesquisa',
        REPORTING_CARDS:'Comunicando',
      },
      CAMPAIGNS:{
        CAMPAIGNS:'Campanhas',
        LIST_CAMPAIGNS:'Lista Campanhas',
      },
      RESTRICTIONS:{
        RESTRICTIONS:'Limitações',
        ALL:'Em geral',
        CARDS:'Cartões',
        TPE:'TPE',
        DAB:'DAB',
        WALLET:'Carteira',
      },
      RULES:{
        RULES:'Regras',
        LIST:'Lista',
        EVENTS:'Eventos'
      },
      ENROLLMENT:{
        ENROLLMENT:'Inscrição',
        NEW:'Novo',
        LIST:'Lista',
        NEW_PIECES_C:'Nova parte de conformidade',
        LIST_PIECES_C:'Compliance part list',
      },
      TRACKERS:{
        TRACKERS:'Rastreadores',
        LIST_TRACKERS:'Lista de rastreadores',
        SUPPLIES:'Suprimentos',
        SUBSTANTIVE_APPEAL:'Apelo substantivo',
      },
      CASH_SUPPLY:{
        CASH_SUPPLY:'Fornecimento de dinheiro',
        CASH_SUPPLY_A:'Agência de fornecimento de dinheiro',
        CASH_SUPPLY_C:'Caixa de abastecimento de dinheiro'
      },
      ITEMS:{
        ITEMS:'Unid',
        lIST_DISTRIBUTION:'Lista de distribuição',
        lIST_BOOKLETS:'Lista de livretos',
        NEW_DISTRIBUTIONS_A:'Nova distribuição de agência',
        NEW_DISTRIBUTIONS_C:'Nova distribuição de dinheiro',
        INVENTOR_DISTRIBUTION:'Distribuição de estoque',
      }

    },
    DASHBOARD:{
      ACTIVITIES:'Atividades',
      DASHBOARD:'Painel',
      TODAY:'Hoje',
      START:'Iniciar:',
      END:'Fim:',
      AGENCIES:'Agências',
      COOPERATIVES:'Cooperativas',
      SERVICES:'Serviços',
      NUMBER_TRS:'Número de transações',
      AMOUNT_TRS:'Valor da transação',
      NUMBER_SUPPLIES:'Número de fornecimento',
      AMOUNT_SUPPLIES:'Quantidade de suprimentos',
      DEBITS:'Débitos',
      AMOUNT_DEBITS:'Débitos de valor',
      CREDITS:'Créditos',
      AMOUNT_CREDITS:'Quantidade de créditos',
      AMOUNT_SERVICES:'Valor da comissão',
      NUMBER_COOPERATIVES:'Número de cooperativa',
      NUMBER_AGENCIES:'Número da agência',
      AMOUNT_COOPERATIVES:'Valor destinado às cooperativas',
      AMOUNT_AGENCIES:'Valor destinado às agências',
      AMOUNT_OM:'Balanço orange money',
      BALANCE:'Balanço geral',
      BALANCE_AGENCIES:'Saldo geral da minha agência',
      NUMBER_C:'Número de caixas',
      AMOUNT_C:'Montante destinado aos caixas',
      MY_SUBSCRIPTION:'Minhas assinaturas',
      BEST_AGENCIES:'Melhores Agências',
      BEST_COOPERATIVES:'Melhores cooperativas',
      BEST_SERVICES:'Melhores serviços',
      RANKING_SERVICES:'Ranking dos melhores serviços',
      RANKING_BEST:'Classificação dos melhores',
      BY_TRS_AMOUNT:'por valor da transação',
      COMMISSIONS_MONTH:'Comissões do mês',
      TRANSACTION_MONTH:'Transação do mês',
      BEST_CRATES:'Melhores caixas',
      BEST_POINT:'Melhor ponto de caminhada',
      POINT_MERCHANT:'Ponto Comerciante',
      CRATES:'Caixas',
      COMMISSION_CRATES:'Comissão em dinheiro',
      TOTAL_COMMISSION_CRATES:'Taxas totais',
      DISTRIBUTORS:'Distribuidores',
      BEST_DISTRIBUTORS:'Melhores Distribuidores',
      COMMISSIONS_DISTRIBUTORS:'Taxas de Distribuidor',
    },

    STAFF:{
      USERS:{
        USERS:'Usuários',
        FULLNAME:'Primeiro nome & Nome',
        LASTNAME:'Nome',
        FIRSTNAME:'Primeiro nome',
        PHONE:'Telefone',
        MAIL:'E-mail',
        USERNAME:'Nome de usuário',
        NUMBER_C:'Nº Caixa',
        NUMBER_M:'Nº Comerciante',
        RULE:'Papel',
        ADD_USER:'Adicionar um usuário',
        ALL:'Todos',
        USERS_WAIT:'Aguardando validação',
        UPADATE:'Para modificar',
        REGENERATE_PWD:'Gerar senha novamente',
        REGENERATE_OPT:'Regenerar código OTP',
        REGENERATE_PIN:'Regenerar código PIN',
        C_REGENERATE_PWD:'Tem certeza de que deseja regenerar a senha deste usuário?',
        C_REGENERATE_PIN:'Tem certeza de que deseja regenerar o código PIN deste usuário?',
        C_REGENERATE_OPT:'Tem certeza de que deseja regenerar o código OTP deste usuário?',
        ACTIVATE_USER:'Tem certeza de que deseja ativar este usuário?',
        REJECT_USER:'Tem certeza de que deseja rejeitar este usuário?',
        NO_USER:'Usuários não encontrados',
        EXPORT_USER:'exportar_users',
        ACTIVATE_USER_SUCCESS:'Conta ativada com sucesso!',
        REJECT_USER_SUCCESS:'Conta rejeitada com sucesso!',
      },
      PERMISSIONS: {
        PERMISSIONS:'Gerenciamento de permissões',
        PREVIOUS:'Anterior',
        NEXT:'Seguindo',
        SUCCESS_UPDATE:'Permissões atualizadas com sucesso',
        ERROR_UPDATE:'Erro ao atualizar as permissões',
        UPADATE_PERMISSIONS:'Atualizar permissões',
      },
      RULE:{
        RULE:'FUNÇÕES',
        NAME:'Nome',
        STATUS:'Status',
        ADD_ROLE:'Adicionar uma função',
        DELETE_ROLE:'Excluir esta função',
        UPDATE_ROLE:'Mudar função',
        EDIT_ROLE:'Editar esta função',
        ADD_ROLE_SUCCESS:'Função criada com sucesso',
        EDIT_ROLE_SUCCESS:'Função alterada com sucesso',
        C_DELETE_ROLE:'Tem certeza de que deseja excluir esta função?',
      }
    },
    AGENCIES:{
      AGENCIES:{
        AGENCIES:'Agências',
        AGENCY_NAME:'Nome Agência',
        AGENCY_CODE:'Código da agência',
        STATE:'Estado',
        DIRECTORATE:'Direção',
        BALANCE:'Pagar',
        CASH_BALANCE:'Saldo de caixa',
        ADDRESS:'Endereço',
        EDIT_AGENCIES:'Editar uma agência',
        SUP_AGENCY:'Agência superior',
        BALANCE_T:'Limite de equilíbrio',
        HOURLY:'De hora em hora',
        OPENING:'Abertura',
        CLOSING:'Fechando',
        ADD_AGENCIES:'Adicionar agência',
        NO_AGENCIES:'Nenhuma agência encontrada',
        INITIAL_BALANCE:'Balanço inicial',
        NUMBER_CRATE:'Número de caixas',
      },
      SUPPLIES:{
        SUPPLIES :'Suprimentos',
        AGENCY_SUPPLIES :'Solicitações de compras de agências',
        START_DATE :'Data de início',
        END_DATE :'Data final',
        AGENCY :'Agência',
        AUTHOR :'Autor',
        STATE :'Estado',
        BALANCE_B :'Equilíbrio antes',
        AMOUNT_RQ :'Quantidade solicitada',
        AMOUNT_GR :'Valor concedido',
        BALANCE_A :'equilíbrio depois',
        VALIDATE_REJECT:'Validado/Rejeitado por',
        PARENT :'Mãe',
        DATE_RQ :'Data da solicitação',
        DATE_RS :'Data de resposta',
        DUPLICATE :'Duplicado',
        ON_HOLD :'Em espera',
        GRANTED :'Garantido',
        REJECTED :'Rejeitado',
        NO_SUPPLIES :'Nenhuma requisição encontrada',
        AMOUT_SUPPLIES :'Quantidade de fornecimento',
        REASONS :'Forneça motivos ',
        SUPPLIES_GRANTED :'Pedido de fornecimento atendido! ',
        SUPPLIES_REJECTED :'Solicitação de aquisição rejeitada! ',
        EXPORT_S :'exportar_suprimentos',
        TRY_AGAIN :'Por favor, tente novamente mais tarde',
        D_EXPORT :'fornecimento_duplicado.pdf',
        E_D_EXPORT :'Falha na exportação duplicada. Tente novamente mais tarde',
      }
    },
    CRATES:{
      CRATES:{
        CRATES:'Caixotes',
        START_DATE :'Data de início',
        END_DATE :'Data final',
        AGENCY :'Agência',
        COOPERATIVES :'Cooperativas',
        CODE_CRATE :'Código de Caixotes',
        CODE_MERCHANT :'Código do comerciante',
        NUMBER_CRATE :'Número da caixa registradora',
        NUMBER_MERCHANT :'Número do comerciante',
        CODE :'Codificado',
        BALANCE:'Saldo',
        CASH_BALANCE:'Saldo de caixa',
        DATE_CREATE:'Data de criação',
        LIST_AGENCIES:'Lista de agências',
        LIST_COOPERATIVES:'Lista de Cooperativas',
        POINT_MERCHANT:'Ponto Comerciante',
        AVAILABLE:'Disponível',
        AFFECTED:'Afetado',
        UPDATE_CRATE:'Modificação de caixa',
        ADD_CRATE:'Adicionando caixa',
        ADD:'adicionado!',
        EDIT:'alteradas!',
        N_CRATE:'Caixa N°',
      }
    }
  },
};
